import React, { FunctionComponent } from "react"
import Aid from "@OneOnOnes/constants/automationId"
import { Icon as IconCA } from "@kaizen/component-library"
import spinnerIcon from "@kaizen/component-library/icons/spinner.icon.svg"
import styles from "./Loader.scss"

const Loader: FunctionComponent = () => (
  <div className={styles.Loader} data-automation-id={Aid.loader}>
    <IconCA icon={spinnerIcon} role="presentation" inheritSize />
  </div>
)

export default Loader
