import React from "react"
import { useQuery } from "@tanstack/react-query"
import { perfApiGet } from "@OneOnOnes/utils/api/utils"
import Loader from "@OneOnOnes/common/Loader/Loader"
import { Route, RouteProps } from "react-router-dom"
import { RedirectTo403 } from "./RedirectTo403"

const ONE_ON_ONES_SESSION_VALID_URL = "/one_on_ones/sessions/valid"

const QUERY_KEY = "one_on_ones.session"
const ONE_HOUR = 60 * 60 * 1000

export const PrivateOneOnOneRoute: React.FC<RouteProps> = ({
  children,
  ...rest
}) => {
  const { isSuccess, isLoading } = useQuery(
    [QUERY_KEY],
    () => perfApiGet(ONE_ON_ONES_SESSION_VALID_URL),
    {
      // after an hour we will revalidate the session if the user navigates
      // between routes
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
    }
  )

  return isLoading ? (
    <Loader />
  ) : isSuccess ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <RedirectTo403 />
  )
}
