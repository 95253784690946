// Automation IDs, used to identify dom elements via the cypress e2e tests.
// Abbreviated because of how common these ids are to be used.
// Try to avoid string concatenation for these automation ids, and stick to
// using the `Aid` enum. The purpose of it is to make if very easy to trace
// where an automation id is used. If you're asking "Where is this component
// getting used?", you can simply call "find usages" in your IDE on the enum
// value, or do a global search.
//
// Note: tasks do not currently follow Aid conventions
enum Aid {
  createOneOnOneDate = "create-one-on-one-date",
  createOneOnOneModal = "create-one-on-one-modal",
  createOneOnOneSuccessToast = "create-one-on-one-success-toast",
  loader = "loader",
  oneOnOneDeleteButton = "one-on-one-delete-button",
  oneOnOneEditDateButton = "one-on-one-edit-date-button",
  oneOnOneDeleteModal = "one-on-one-delete-modal",
  oneOnOneEditDateModal = "one-on-one-edit-date-modal",
  oneOnOneDeleteSuccessToast = "one-on-one-delete-success-toast",
  oneOnOneHomepageEmptyState = "one-on-one-homepage-emptyState", // oneOnOne conversation
  oneOnOneMenuButton = "one-on-one-menu-button",
  oneOnOneNothingScheduledTable = "one-on-one-nothing-scheduled-table", // oneOnOne conversation
  oneOnOnesAdminParticipationDateRange = "one-on-ones-admin-participation-date-range",
  oneOnOnesAdminParticipationTotalNumber = "one-on-ones-admin-participation-total-number",
  oneOnOnesAdminReportTable = "one-on-ones-admin-report-table",
  oneOnOnesAdminStatsFilter = "one-on-ones-admin-stats-filter",
  oneOnOnesContent = "one-on-ones-content",
  // oneOnOne conversation
  oneOnOnesArchivedList = "one-on-ones-archived-list",
  oneOnOnesArchivedListContainerItem = "one-on-ones-archived-list-container-item",
  oneOnOnesPageTitle = "one-on-ones-page-title",
  oneOnOnesResumeButton = "one-on-ones-resume-button",
  oneOnOnesScheduleButton = "one-on-ones-schedule-button",
  oneOnOnesRowParagraph = "one-on-ones-row-paragraph",
  oneOnOnesRelinkInfoUpcomingSchedule = "one-on-ones-relink-info-upcoming-schedule",
  oneOnOnesRelinkInfoNothingScheduled = "one-on-ones-relink-info-nothing-scheduled",
  oneOnOnesScheduleSubtitle = "one-on-ones-schedule-subtitle",
  oneOnOnesDateUpdatedAudit = "one-on-ones-date-updated-audit",
  oneOnOnesSetupButton = "one-on-ones-setup-button",
  oneOnOnesUpcomingList = "one-on-ones-upcoming-list",
  oneOnOnesUpcomingListContainerItem = "one-on-ones-upcoming-list-container-item",
  pauseScheduleSuccessToast = "pause-schedule-success-toast",
  pauseScheduleWarningMessage = "pause-schedule-cautionary-notification",
  setupScheduleSuccessToast = "setup-schedule-success-toast",
  scheduleOneOnOneDate = "schedule-one-on-one-date", // oneOnOne home
  scheduleOneOnOneModal = "schedule-one-on-one-modal",
  scheduleOneOnOneRepeat = "schedule-one-on-one-repeat",
  oneOnOneTime = "one-on-one-time", // oneOnOne home
  calendar = "calendar",
  calendarDayButton = "calendar-day-button",
  tableLoading = "table-loading",
  errorState = "error-state",
  calendarSyncConnectButton = "calendar-sync-connect-button",
  calendarSyncDisconnectButton = "calendar-sync-disconnect-button",
  CalendarConnectionIntroModal = "calendar-connection-intro-modal",
  CalendarConnectionInfoModal = "calendar-connection-info-modal",
  titleBlockPrimaryActionButton = "title-block-primary-action-button",
  calendarSyncInfo = "calendar-sync-info",
  calendarSyncInfoInline = "calendar-sync-info-inline",
}

export default Aid
